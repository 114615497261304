
import React from "react";
import styled from "styled-components"
import media from "styled-media-query";

const LogoType = styled.div`
    width: 100%;
    margin-bottom: 12px;

    svg {
        max-width: 120px;
    }

    ${media.greaterThan("medium")`
        padding: 0;
        margin-bottom: 25px;

        input {
            font-size: 24px;
        }
    `}
`

const FooterLogo = props => (
  <LogoType>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.86 43.86" fill={props.color}>
        <title>logo</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M64.54,25.19c-1.57,4.65-3,9-4.41,13.65H60c-1.45-4.65-2.9-9-4.47-13.65L51.19,12.32H45.51L56.63,43h6.88L74.63,12.32H69Z"/>
                <path d="M81.06,24.71c.54-5.86,4-9.12,8.88-9.12,4.71,0,8.21,3.14,8.76,9.12Zm22.29-2.54c-1.57-6.58-6.47-10.69-13.41-10.69-8.94,0-14.26,6.7-14.26,15.77,0,10,5.38,16.61,14.44,16.61,7.85,0,12.5-4.65,13.53-10H98.21c-.72,3.44-3.62,5.92-8.09,5.92-5,0-8.88-3.44-9.12-11h23a29,29,0,0,0-.6-6.59"/>
                <path d="M148.91,25.19c-1.57,4.65-3,9-4.41,13.65h-.12c-1.45-4.65-2.9-9-4.47-13.65l-4.35-12.87h-5.68L141,43h6.89L159,12.32h-5.68Z"/>
                <rect x="163.35" width="5.32" height="5.98"/>
                <rect x="163.35" y="12.32" width="5.32" height="30.69"/>
                <polygon points="109.29 13.17 109.29 17.59 109.29 43.01 114.55 43.01 114.55 17.59 125.87 17.59 125.87 12.32 109.29 12.32 109.29 13.17"/>
                <path d="M179.46,24.71c.55-5.86,4-9.12,8.88-9.12,4.71,0,8.22,3.14,8.76,9.12Zm22.29-2.54c-1.57-6.58-6.46-10.69-13.41-10.69-8.94,0-14.26,6.7-14.26,15.77,0,10,5.38,16.61,14.44,16.61,7.86,0,12.51-4.65,13.54-10h-5.44c-.73,3.44-3.63,5.92-8.1,5.92-5,0-8.88-3.44-9.12-11h23a28.34,28.34,0,0,0-.61-6.59"/><path d="M244.49,12.32l-3.26,12.81c-1.15,4.53-2.3,9.25-3.39,13.78h-.12c-1.08-4.41-2.23-9-3.38-13.48l-3.5-13.11h-7.62l-3.5,13.11c-1.21,4.47-2.36,9.07-3.38,13.48h-.12c-1.09-4.53-2.24-9.25-3.39-13.78l-3.26-12.81h-5.38L212.35,43H220l3.63-13.29c1.14-4.47,2.29-8.82,3.38-13.29h.12c1.09,4.47,2.24,8.82,3.38,13.29L234.1,43h7.61l8.15-30.69Z"/>
                <path d="M21.5,37.81a16.31,16.31,0,1,1,16.31-16.3A16.32,16.32,0,0,1,21.5,37.81M21.5,0A21.51,21.51,0,1,0,43,21.51,21.53,21.53,0,0,0,21.5,0"/>
            </g>
        </g>
    </svg>
</LogoType>
);

export default FooterLogo;

