import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Text, { Grid, Section } from "./Theme";
import media from "styled-media-query";

// import Mark from "./Header/mark";
import Logo from "./footerlogo";
import chevronRight from '../images/icons/icon__chevron--white--right.svg'

const FooterWrapper = styled.div`
    background-color: #e0e0e0;
`;

const FooterBottomGrid = styled(Section)`
    ${media.lessThan("large")`
        display: grid !important;
    `}
`;

const FooterBottom = styled.div`
    grid-column: 2/12;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 30px 0;

    h3 {
        font-size: 12px;
        opacity: 0.4;
        margin: 0;
        padding: 0;
    }
`;

const FooterLogo = styled.div`
    grid-column: 11/12;

    ${media.lessThan("large")`
        grid-column: 1/5;
        grid-row: 2;
    `}

    svg {
        width: 100%;
    }
`;

const LogoCopy = styled(Text)`
    color: var(--grey);
    font-family: var(--nhp);
    font-size: 15px;
    line-height: 1.2;
`;

const FooterSubtitle = styled(Text)`
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    line-height: 36px;
    margin-bottom: 10px;
`;

const FooterGridCol = styled.div`
    padding-top: 0px;
    padding-bottom: 0;
    padding: 80px 0;
    margin-bottom: 0px;
    align-self: start;
    text-align: left;
    max-width: 100%;

    &:nth-child(1) {
        grid-column: 1/13;
        padding: 0;

        ${media.greaterThan("large")`
            grid-column: 1/4;
        `}
    }

    &:nth-child(2) {
        ${media.greaterThan("large")`
            grid-column: 4/6;
        `}

        grid-column: 1/6;
        padding: 0;
    }

    &:nth-child(3) {
        ${media.greaterThan("large")`
            grid-column: 6/8;
            padding: 0;
        `}

        grid-column: 6/12;
        padding: 0;
    }

    &:nth-child(4) {
        grid-column: 1/6;
        padding: 25px 0 0 0;
        input {
            font-size: 18px;
        }

        ${media.greaterThan("large")`
            grid-column: 8/10;
            padding: 0;
            input {
                font-size: 24px;
            }
        `}
    }

    &:nth-child(5) {
        grid-column: 1/11;
        padding: 25px 0 0 0;
        input {
            font-size: 18px;
        }

        ${media.lessThan("medium")`
            grid-column: 1/13;
        `}

        ${media.greaterThan("large")`
            grid-column: 10/13;
            padding: 0;
            input {
                font-size: 24px;
            }
        `}
    }

    h3 {
        opacity: 0.5;
    }

    form {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 20px;

        input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0;
            width: 100%;
            margin: 0;
            background-color: #f1f1f1;
            background-repeat: no-repeat;
            background-position: 1.5rem center;
            height: 63px;
            font-size: 20px;
            padding: 1rem 2rem;
            grid-column: 1/13;

            &::placeholder {
                opacity: 0.3;
            }

            &[type="submit"] {
                border: none;
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(${chevronRight});
                background-color: var(--black);
                border: none;
                color: var(--white);
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 63px;
                height: 63px;
                font-size: 20px;
                line-height: 1;
            }
        }
    }
`;

const FooterGrid = styled.div`
    ${Grid};
    padding-top: 0;
    padding-bottom: 0;
    align-content: start;
    justify-content: start;
    padding: 60px 0;
    grid-auto-flow: row;

    ${media.lessThan("large")`
        display: grid !important;
        padding: 50px 25px;
    `}

    ${media.greaterThan("large")`
        font-size: 16px;
        line-height: 24px;
    `}

    ul {
        grid-column: span 4;
        padding: 0;
        margin: 0;
        align-self: start;
    }

    p {
        font-size: 18px;
    }
`;

const Footer = () => (
    <FooterWrapper>
        <FooterGrid>
            <FooterGridCol>
                <FooterLogo>
                    <Logo color="black" />
                    <LogoCopy className="margin-bottom--50" col="2/6">
                        Copyright {new Date().getFullYear()} Overview
                    </LogoCopy>
                </FooterLogo>
            </FooterGridCol>
            <FooterGridCol>
                <FooterSubtitle caption>Explore</FooterSubtitle>
                <Text sub>
                    <Link className="main-nav-link" to="/stories">
                        Stories
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/daily">
                        Daily
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/digital-index">
                        Index
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/map">
                        Map
                    </Link>
                </Text>
            </FooterGridCol>
            <FooterGridCol>
                <FooterSubtitle caption>
                    <Link to="/shop/">Shop</Link>
                </FooterSubtitle>
                <Text sub>
                    <Link className="main-nav-link" to="/shop/books">
                        Books
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/shop/prints">
                        Prints
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/custom">
                        Custom
                    </Link>
                </Text>
            </FooterGridCol>
            <FooterGridCol>
                <FooterSubtitle caption>
                    <Link to="/about/">About</Link>
                </FooterSubtitle>
                <Text sub>
                    <Link className="main-nav-link" to="/collaborations">
                        Projects
                    </Link>
                </Text>
                <Text sub>
                    <Link className="main-nav-link" to="/press">
                        Press
                    </Link>
                </Text>

                <Text sub>
                    <Link className="main-nav-link" to="/contact">
                        Contact
                    </Link>
                </Text>
            </FooterGridCol>
            <FooterGridCol>
                <FooterSubtitle caption>Subscribe</FooterSubtitle>
                <form
                    action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                    method="post"
                    id="revue-form"
                    name="revue-form"
                    target="_blank">
                    <input
                        className="revue-form-field"
                        placeholder="Enter your email to subscribe &#8594;"
                        type="email"
                        name="member[email]"
                        id="member_email"
                    />
                    <input
                        type="submit"
                        value=""
                        name="member[subscribe]"
                        id="member_submit"></input>
                </form>
            </FooterGridCol>
        </FooterGrid>
    </FooterWrapper>
);

export default Footer;
