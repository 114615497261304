import React from 'react'
import { graphql } from 'gatsby';
import styled from 'styled-components'
import Text, {Section} from '../components/Theme'
// import { system, Grid } from 'styled-system'
//import { HelmetDatoCms } from 'gatsby-source-datocms'
//import Img from 'gatsby-image'
import Layout from "../components/layout"
import Header from "../components/Header";
import Footer from '../components/footer';


const PageAbout = styled.div`
  
`;

const Caption = styled(Text)`
margin-bottom: 20px;
font-size: 12px;
opacity:.5;
  
`;

const AboutBlock = styled.div`
  grid-column: 2/5;
    
  padding-right: 0px;
`;
const Emails = styled.div`
  grid-column: 5/7;
`;

const People = styled.div`
  grid-column: 7/9;
`;


const Partners = styled.div`
  grid-column: 9/11;
`;  
const AboutText = styled(Text)`
  font-size: 17px;
  line-height: 24px;
  span, a {
    opacity:.5;
    display: block;
  }
`
const PartnerText = styled(Text)`
font-size: 17px;
  line-height: 24px;    
  a {
      opacity:  1;
      
  }
`;

const About = ({ data: { about } }) => (
  <Layout>
      <PageAbout>
        <Header  content_color="black"/>
        <Section small><Text ultra col="2/12">About</Text></Section>
        

          <Section large stacked top>
            <AboutBlock>
            <Caption caption>Mission</Caption>
              <AboutText body>{about.mission}</AboutText>
            </AboutBlock>
            <Emails>
              <div className="about-row">

              <Caption caption>People</Caption>
              {about.people.map(person => (
                    <div className={`about-${person.model.apiKey}`} key={person.id}>
                        {person.model.apiKey === "person" && (
                            <AboutText>{person.title}<span>{person.email}</span></AboutText>
                        )}
                    </div>   
                ))}
                
              </div>
            </Emails>
            
            <People>
            <div className="about-row">
                <Caption caption>Emails</Caption>
                {about.contacts.map(contact => (
                    <div className={`about-${contact.model.apiKey}`} key={contact.id}>
                        {contact.model.apiKey === "contact" && (
                            <AboutText>{contact.name}<a href={`mailto:${contact.title}`}>{contact.title}</a></AboutText>
                        )}
                    </div>   
                ))}
             
              </div>
            </People>
            
            <Partners>
              <div className="about-row">
                <Caption caption>Imagery Partners</Caption>
                {about.partners.map(partner => (
                    <div className={`about-${partner.model.apiKey}`} key={partner.id}>
                        {partner.model.apiKey === "partner" && (
                            <PartnerText><a href={partner.link}>{partner.name}</a></PartnerText>
                        )}
                    </div>   
                ))}
              </div>
            </Partners>
          </Section>
          
            
    </PageAbout>
    <Footer/>
  </Layout>
)

export default About

export const query = graphql`
  query AboutQuery {
    about: datoCmsAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mission
      people {
            model {
                apiKey
            }
            email
            title
            id
        }
        partners {
          model {
            apiKey
          }
          link
          name
          id
        }
        contacts {
          model {
            apiKey
          }
          name
          title
          id
        }
    
    
    }
  }
`
